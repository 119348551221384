import React, { useEffect, useState } from "react";
import {
  BodyInfo,
  SellDetail,
  DivOrgInfo,
  DivOrgTitle,
  HeaderInfo,
  InfoSellDetails,
  Item,
  NameInfo,
  RowInfo,
  TableSell,
  TableInfo,
  TitleIn,
  Value,
  DivFilter,
  DivOrgFilter,
  FilterLabel,
  CodInput,
  InputDate,
  DivOrgBtn,
  BtnCancel,
  BtnSearch,
} from "./DetailSellStyle";
import FormatDatesFront from "../../../utils/formatDateFront.mjs";
import { Close, Search } from "@styled-icons/material";
import { DivOrgInputs } from "../../Tables/TableInfoClient/TableInfoClientStyle";

export default function DetailSell(props) {
  const infoOrders = props.resumeOrders;

  
  // Instancia de formatação de datas
  const formatDate = new FormatDatesFront();

  const [showList, setShowList] = useState();
    // Estado para filtros
    const [filterCodOrder, setFilterCodOrder] = useState("");
    const [filterDateStartOrder, setFilterDateStartOrder] = useState("");
    const [filterDateFinishOrder, setFilterDateFinishOrder] = useState("");
    const [filterInfoOrder, setFilterInfoOrder] = useState([]);
  

  const createList = (dataOrders) => {
    setShowList(true);
    if (showList) {
      setFilterInfoOrder(dataOrders);
    }
  };

  const filterOrders = () => {
    const filterList = infoOrders
      .filter((order) =>
        filterCodOrder > 0 ? order.idOrder === filterCodOrder : order
      )
      .filter((product) =>
        filterDateStartOrder
          ? formatDate.compareDatesAfter(
              product.dateCreated,
              filterDateStartOrder
            ) >= 0
          : product
      )
      .filter((product) =>
        filterDateFinishOrder
          ? formatDate.compareDatesAfter(
              product.dateCreated,
              filterDateFinishOrder
            ) <= -0
          : product
      );

    setFilterInfoOrder(filterList);
  };

  useEffect(() => {
    createList(infoOrders);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoOrders]);

  return (
    <TableSell>
      <DivFilter>
        <DivOrgFilter>
          <DivOrgInputs>
            <FilterLabel>Nº do pedido</FilterLabel>
            <CodInput
              value={filterCodOrder}
              onValueChange={(values, sourceInfo) => {
                setFilterCodOrder(parseInt(values.value));
              }}
            />
          </DivOrgInputs>
          <DivOrgInputs>
            <FilterLabel>Data Inicio</FilterLabel>
            <InputDate
              type="date"
              value={filterDateStartOrder}
              onChange={(e) => setFilterDateStartOrder(e.target.value)}
            />
          </DivOrgInputs>
          <DivOrgInputs>
            <FilterLabel>Data Final</FilterLabel>
            <InputDate
              type="date"
              value={filterDateFinishOrder}
              onChange={(e) => setFilterDateFinishOrder(e.target.value)}
            />
          </DivOrgInputs>
          <DivOrgBtn>
            <BtnSearch type="button" onClick={filterOrders}>
              <Search />
            </BtnSearch>
            <BtnCancel
              type="button"
              onClick={() => {
                setFilterCodOrder("");
                setFilterDateStartOrder("");
                setFilterDateFinishOrder("");
                setFilterInfoOrder(infoOrders);
              }}
            >
              <Close />
            </BtnCancel>
          </DivOrgBtn>
        </DivOrgFilter>
      </DivFilter>
      <InfoSellDetails>
        <SellDetail>
          <DivOrgTitle>
            <TitleIn>Vendas</TitleIn>
          </DivOrgTitle>
          <DivOrgInfo>
            <TableInfo>
              <HeaderInfo>
                <RowInfo>
                  <NameInfo>ID</NameInfo>
                  <NameInfo>Data de Venda</NameInfo>
                  <NameInfo>Desconto</NameInfo>
                  <NameInfo>Valor com Desconto</NameInfo>
                  <NameInfo>Valor sem desconto</NameInfo>
                  <NameInfo>Valor do Desconto</NameInfo>
                  <NameInfo>Valor pago</NameInfo>
                  <NameInfo>Valor do troco</NameInfo>
                </RowInfo>
              </HeaderInfo>
              <BodyInfo>
                {filterInfoOrder?.map((order, index) => {
                  return (
                    <RowInfo key={index}>
                      <Item>{index + 1}</Item>
                      <Item>{order.dateCreated}</Item>
                      <Item>{order.discountOption}</Item>
                      <Item>
                        <Value
                          value={order.valueWithDiscount}
                          displayType="text"
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale
                          decimalScale={2}
                          prefix={"R$ "}
                        />
                      </Item>
                      <Item>
                        <Value
                          value={order.valueNoDiscount}
                          displayType="text"
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale
                          decimalScale={2}
                          prefix={"R$ "}
                        />
                      </Item>
                      <Item>
                        <Value
                          value={order.valueDiscount}
                          displayType="text"
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale
                          decimalScale={2}
                          prefix={"R$ "}
                        />
                      </Item>
                      <Item>
                        <Value
                          value={order.valueClientPayed}
                          displayType="text"
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale
                          decimalScale={2}
                          prefix={"R$ "}
                        />
                      </Item>
                      <Item>
                        <Value
                          value={order.valueChange}
                          displayType="text"
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale
                          decimalScale={2}
                          prefix={"R$ "}
                        />
                      </Item>
                    </RowInfo>
                  );
                })}
              </BodyInfo>
            </TableInfo>
          </DivOrgInfo>
        </SellDetail>
      </InfoSellDetails>
    </TableSell>
  );
}
