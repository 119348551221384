import React, { useEffect, useState } from "react";
import {
  DivStockItem,
  TitleStockItem,
  DivScreenStockItem,
} from "./StockItemStyles";
import NewItem from "../../components/Forms/NewItem/NewItem";
import { allProviders } from "../../store/registers/provider/provider.actions";
import { useDispatch } from "react-redux";
import { allProducts } from "../../store/registers/products/products.actions";

export default function StockItem() {
  const dispatch = useDispatch();
  const [providersInfo, setProvidersInfo] = useState([]);
  const [productsInfo, setProductsInfo] = useState([]);

  const getListProviders = async () => {
    const listProviders = await dispatch(allProviders());
    setProvidersInfo(listProviders.payload);
  };

  const getListProducts = async () => {
    const listProducts = await dispatch(allProducts());
    setProductsInfo(listProducts.payload);
  };

  useEffect(() => {
    getListProviders();
    getListProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DivStockItem>
      <TitleStockItem>Adicionar Item no Estoque</TitleStockItem>
      <DivScreenStockItem>
        <NewItem providersData={providersInfo} productsInfo={productsInfo} />
      </DivScreenStockItem>
    </DivStockItem>
  );
}
