import React, { useState } from "react";
import {
  DivOrgCardDelete,
  DivOrgBtnClose,
  BtnClose,
  DivOrgLabel,
  DivOrgBtn,
  BtnConfirm,
  BtnCancel,
  LabelDelete,
  DivOrgDelete,
  InfoResult,
  DivOrgResults,
  DivOrgLoading,
} from "./DeleteEmployeeStyle";
import { Close } from "@styled-icons/material";
import { downEmployee } from "../../../store/registers/employee/employee.actions";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";

export default function DeleteEmployee(props) {
  const employeeRemove = props.selectedEmployee;
  const dispatch = useDispatch();
  const [deleteEmployeeInfo, setDeleteEmployeeInfo] = useState([]);
  const [loading, setLoading] = useState();

  const deleteEmployee = async (dataEmployee) => {
    setLoading(true);
    const idEmployee = dataEmployee.idEmployee;
    const deleteEmployee = await dispatch(downEmployee(idEmployee));
    setDeleteEmployeeInfo(deleteEmployee.payload);
    setTimeout(() => {
      setLoading(false);
    }, 500);
    setTimeout(() => {
      props.setLoadingEmployees(true);
      props.setDelEmployeeOption(false)
    }, 4000);
  };

  const parseName = (oneName, secondName) => {
    const firstName = oneName || "";
    const lastName = secondName || "";
    var fullName = "";
    if (lastName.length > 0) {
      fullName = firstName.concat(" ", lastName);
    } 
    else {
      fullName = firstName.concat("", lastName);
    }
    const formatName = fullName.split(" ");
    for (var i = 0; i < formatName.length; i++) {
      formatName[i] =
        formatName[i].charAt(0).toUpperCase() + formatName[i].slice(1);
    }
    let result = formatName.join(" ");

    return result;
  };

  return (
    <DivOrgDelete>
      <DivOrgBtnClose>
        <BtnClose onClick={() => props.setDelEmployeeOption(false)}>
          <Close />
        </BtnClose>
      </DivOrgBtnClose>
      <DivOrgCardDelete showDiv={props.delEmployeeOption}>
        <DivOrgLabel>
          <LabelDelete>
            Deseja apagar o funcionario:{" "}
            {parseName(employeeRemove.firstName, employeeRemove.lastName)} ?
          </LabelDelete>
        </DivOrgLabel>
        <DivOrgBtn>
          <BtnConfirm onClick={() => deleteEmployee(employeeRemove)}>
            Sim
          </BtnConfirm>
          <BtnCancel onClick={() => props.setDelEmployeeOption(false)}>
            Não
          </BtnCancel>
        </DivOrgBtn>
      </DivOrgCardDelete>
      {loading ? (
        <DivOrgLoading>
          <ClipLoader speedMultiplier={3} />
        </DivOrgLoading>
      ) : (
        (deleteEmployeeInfo.errorStatus && (
          <DivOrgResults>
            <InfoResult>{deleteEmployeeInfo.message}</InfoResult>
          </DivOrgResults>
        )) ||
        (deleteEmployeeInfo.successStatus && (
          <DivOrgResults>
            <InfoResult>{deleteEmployeeInfo.message}</InfoResult>
          </DivOrgResults>
        ))
      )}
    </DivOrgDelete>
  );
}
