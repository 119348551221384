import React, { useEffect, useState } from "react";
import {
  DivNewSon,
  FormSon,
  DivOrgSon,
  LabelSon,
  InputSon,
  SubmitFormSon,
  SelectOption,
  DivOrgResults,
  InfoResult,
  DivOrgLoading,
  DivPopUpNewSon,
  DivBtnClose,
  BtnClose,
  TitleNewSon,
  InputDate,
} from "./NewSonPopUpStyle";

import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { ClipLoader } from "react-spinners";
import { Close } from "@styled-icons/material";
import { createClientSon } from "../../../store/registers/clientSons/clientSons.actions";

export default function NewSonPopUp(props) {
  const listClients = props.listClientsInfo;

  const dispatch = useDispatch();
  const { register, handleSubmit, reset, formState } = useForm();

  const [sonInfo, setSonInfo] = useState([]);
  const [selectClient, setSelectClient] = useState([]);
  const [optionsClients, setOptionsClients] = useState([]);
  const [isClearable] = useState(true);

  const [loadingNewSon, setLoadingNewSon] = useState(false);

  const createSon = async (dataSon) => {
    setLoadingNewSon(true);
    dataSon.idFather = selectClient.value;

    const sonCreate = await dispatch(createClientSon(dataSon));
    console.log(sonCreate.payload);
    setSonInfo(sonCreate.payload);
    setTimeout(() => {
      setLoadingNewSon(false);
    }, 500);
  };

  useEffect(() => {
    if (listClients?.length > 0) {
      const optionsClients = listClients.map((client) => ({
        value: client.idClient,
        label:
          client.clientName + " " + client.lastName + " / " + client.docClient,
      }));
      setOptionsClients(optionsClients);
    }
  }, [listClients]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      setTimeout(reset(), 1000);
      setTimeout(() => {
        setSonInfo([]);
      }, 5000);
    }
  }, [formState, reset, setSonInfo]);

  return (
    <DivPopUpNewSon show={props.showPopSon}>
      <DivBtnClose>
        <BtnClose
          type="button"
          onClick={() => {
            props.setShowPopSon(false);
          }}
        >
          <Close />
        </BtnClose>
      </DivBtnClose>
      <DivNewSon>
        <TitleNewSon>Adicionar Filho</TitleNewSon>
        <FormSon onSubmit={handleSubmit(createSon)}>
          <DivOrgSon>
            <LabelSon>Nome do Cliente</LabelSon>
            <SelectOption
              name="nameClient"
              placeholder="Buscar nome do Cliente"
              options={optionsClients}
              isClearable={isClearable}
              onChange={setSelectClient}
            />
          </DivOrgSon>
          <DivOrgSon>
            <LabelSon>Nome do Filho</LabelSon>
            <InputSon
              {...register("nameSon", {
                required: true,
              })}
            />
          </DivOrgSon>
          <DivOrgSon>
            <LabelSon>Data de nascimento</LabelSon>
            <InputDate
              type="date"
              {...register("birthdaySon", {
                required: true,
              })}
            />
          </DivOrgSon>
          <SubmitFormSon type="client">Cadastrar</SubmitFormSon>
        </FormSon>
        {loadingNewSon ? (
          <DivOrgLoading>
            <ClipLoader speedMultiplier={3} />
          </DivOrgLoading>
        ) : (
          (sonInfo.errorStatus && (
            <DivOrgResults>
              <InfoResult>{sonInfo.message}</InfoResult>
            </DivOrgResults>
          )) ||
          (sonInfo.successStatus && (
            <DivOrgResults>
              <InfoResult>{sonInfo.message}</InfoResult>
            </DivOrgResults>
          ))
        )}
      </DivNewSon>
    </DivPopUpNewSon>
  );
}
