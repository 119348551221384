import React, { useEffect, useState } from "react";
import {
  BodyInfo,
  AllStockDetail,
  DivOrgDetail,
  DivOrgInfo,
  DivOrgTitle,
  HeaderInfo,
  InfoAllStockDetails,
  Item,
  NameInfo,
  RowInfo,
  TableAllStock,
  TableInfo,
  TitleIn,
  Value,
} from "./DetailAllStockStyle";

export default function DetailAllStock(props) {
  const infoStockOut = props.resumeStockOut;
  // const infoStockIn = props.resumeStockIn;
  const infoStockNow = props.resumeStockNow;

  const [showList, setShowList] = useState();
  // const [listStockIn, setListStockIn] = useState([]);
  const [listStockNow, setListStockNow] = useState([]);
  const [listStockOut, setListStockOut] = useState([]);

  const createList = (dataStockOut, dataStockNow) => {
    setShowList(true);
    if (showList) {
      setListStockOut(dataStockOut);
      setListStockNow(dataStockNow);
      // setListStockIn(dataStockIn);
    }
  };

  useEffect(() => {
    createList(infoStockOut, infoStockNow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoStockOut, infoStockNow]);

  return (
    <DivOrgDetail>
      <DivOrgTitle></DivOrgTitle>
      <TableAllStock>
        <InfoAllStockDetails>
          <AllStockDetail>
            <DivOrgTitle>
              <TitleIn>Estoque Atual</TitleIn>
            </DivOrgTitle>
            <DivOrgInfo>
              <TableInfo>
                <HeaderInfo>
                  <RowInfo>
                    <NameInfo>ID</NameInfo>
                    <NameInfo>Produto</NameInfo>
                    <NameInfo>Valor de Venda</NameInfo>
                    <NameInfo>QTD no Estoque</NameInfo>
                    <NameInfo>Valor Total</NameInfo>
                  </RowInfo>
                </HeaderInfo>
                <BodyInfo>
                  {listStockNow?.map((product, index) => {
                    return (
                      <RowInfo key={index}>
                        <Item>{index + 1}</Item>
                        <Item>{product.nameProduct}</Item>
                        <Item>
                          <Value
                            value={product.priceSell}
                            displayType="text"
                            decimalSeparator=","
                            thousandSeparator="."
                            fixedDecimalScale
                            decimalScale={2}
                            prefix={"R$ "}
                          />
                        </Item>
                        <Item>{product.qtdStock}</Item>
                        <Item>
                          <Value
                            value={product.priceTotal}
                            displayType="text"
                            decimalSeparator=","
                            thousandSeparator="."
                            fixedDecimalScale
                            decimalScale={2}
                            prefix={"R$ "}
                          />
                        </Item>
                      </RowInfo>
                    );
                  })}
                </BodyInfo>
              </TableInfo>
            </DivOrgInfo>
          </AllStockDetail>
          <AllStockDetail>
            <DivOrgTitle>
              <TitleIn>Entradas no Estoque</TitleIn>
            </DivOrgTitle>
            <DivOrgInfo>
              <TableInfo>
                <HeaderInfo>
                  <RowInfo>
                    <NameInfo>ID</NameInfo>
                    <NameInfo>Data de Pagamento</NameInfo>
                    <NameInfo>Descrição</NameInfo>
                    <NameInfo>Valor Pago</NameInfo>
                    <NameInfo>Forma de pagamento</NameInfo>
                    <NameInfo>Status</NameInfo>
                  </RowInfo>
                </HeaderInfo>
                <BodyInfo>
                  {/* {listExpense?.map((expense, index) => {
                    return (
                      <RowInfo key={index}>
                        <Item>{index + 1}</Item>
                        <Item>{expense.datePayment}</Item>
                        <Item>{expense.expenseType}</Item>
                        <Item>
                          <Value
                            value={expense.value}
                            displayType="text"
                            decimalSeparator=","
                            thousandSeparator="."
                            fixedDecimalScale
                            decimalScale={2}
                            prefix={"R$ "}
                          />
                        </Item>
                        <Item>{expense.formPayment}</Item>
                        <Item>{expense.status}</Item>
                      </RowInfo>
                    );
                  })} */}
                </BodyInfo>
              </TableInfo>
            </DivOrgInfo>
          </AllStockDetail>
          <AllStockDetail>
            <DivOrgTitle>
              <TitleIn>Saídas do Estoque</TitleIn>
            </DivOrgTitle>
            <DivOrgInfo>
              <TableInfo>
                <HeaderInfo>
                  <RowInfo>
                    <NameInfo>ID</NameInfo>
                    <NameInfo>Data de saida</NameInfo>
                    <NameInfo>Motivo</NameInfo>
                    <NameInfo>Detalhes</NameInfo>
                    <NameInfo>QTD Retirada</NameInfo>
                    <NameInfo>Preço Unitario</NameInfo>
                  </RowInfo>
                </HeaderInfo>
                <BodyInfo>
                  {listStockOut?.map((stockOut, index) => {
                    return (
                      <RowInfo key={index}>
                        <Item>{index + 1}</Item>
                        <Item>{stockOut.dateOut}</Item>
                        <Item>{stockOut.reason}</Item>
                        <Item>{stockOut.details}</Item>
                        <Item>{stockOut.qtdRemoveNoBatch}</Item>
                        <Item>
                          <Value
                            value={stockOut.valueTotalRemove}
                            displayType="text"
                            decimalSeparator=","
                            thousandSeparator="."
                            fixedDecimalScale
                            decimalScale={2}
                            prefix={"R$ "}
                          />
                        </Item>
                      </RowInfo>
                    );
                  })}
                </BodyInfo>
              </TableInfo>
            </DivOrgInfo>
          </AllStockDetail>
        </InfoAllStockDetails>
      </TableAllStock>
    </DivOrgDetail>
  );
}
