import React, { useEffect, useState } from "react";
import {
  DivPosition,
  TitlePosition,
  DivScreenPosition,
} from "./WorkPositionStyle";
import NewPosition from "../../components/Forms/NewWorkPosition/NewWorkPosition";
import SearchPosition from "../../components/Search/SearchWorkPosition/SearchWorkPosition";

import { useDispatch } from "react-redux";
import { allPosition } from "../../store/registers/workPosition/position.actions";

export default function PositionRegister() {
  const dispatch = useDispatch();
  const [positionsInfo, setPositionsInfo] = useState([]);
  const [disableFilter, setDisableFilter] = useState(false);

  const getListPositions = async () => {
    const listPosition = await dispatch(allPosition());
    setPositionsInfo(listPosition.payload);
  };

  useEffect(() => {
    getListPositions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (positionsInfo.errorStatus === true) {
      alert(positionsInfo.message);
      setDisableFilter(true);
    } else {
      setDisableFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionsInfo.errorStatus]);
  return (
    <DivPosition>
      <TitlePosition>Cadastro de Profissão</TitlePosition>
      <DivScreenPosition>
        <NewPosition getListPositions={getListPositions} />
        <SearchPosition
          positionsInfo={positionsInfo?.positions}
          getListPositions={getListPositions}
          disableFilter={disableFilter}
        />
      </DivScreenPosition>
    </DivPosition>
  );
}
