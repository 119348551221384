import React, { useEffect, useState } from "react";
import {
  BtnEdit,
  BtnRemove,
  BtnSearch,
  BtnView,
  DivBtnEdit,
  DivBtnFilter,
  DivIdEmployee,
  DivEmployee,
  DivInfo,
  DivEmployeeInfo,
  DivSearch,
  DivSearchEmployee,
  DivTableSearch,
  NameInput,
  NameLabel,
  IdInfo,
  SpanName,
  DivOrgLoading,
  DivCardEmployee,
  DivBtnSearch,
  FormatCPF,
  BtnCancel,
  FormatCPFText,
  DivOrgInput,
} from "./SearchEmployeeStyle";
import UpdateEmployee from "../../Update/UpdateEmployee/UpdateEmployee";
import InfoEmployee from "../../Info/InfoEmployee/InfoEmployee";
import DeleteEmployee from "../../DeleteComponent/DeleteEmployee/DeleteEmployee";

import { Title } from "../../Status/StatusSell/StatusSellStyle";
import {
  Close,
  Edit,
  PersonRemove,
  Search,
  Visibility,
} from "@styled-icons/material";

import { ClipLoader } from "react-spinners";

export default function SearchEmployee(props) {
  const employeesInfo = props.employeeInfo;

  const [employeePopUp, setEmployeePopUp] = useState(false);
  const [delEmployeeOption, setDelEmployeeOption] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [selectedEmployeeView, setSelectedEmployeeView] = useState();
  const [filterCPFEmployee, setFilterCPFEmployee] = useState();
  const [filterNameEmployee, setFilterNameEmployee] = useState("");
  const [filterInfoEmployee, setFilterInfoEmployee] = useState([]);

  const [employeeView, setEmployeeView] = useState(false);

  const [showList, setShowList] = useState(false);
  const [loading, setLoading] = useState();
  const [loadingEmployees, setLoadingEmployees] = useState(false);
  const [dataEmployeeUpdate, setDataEmployeeUpdate] = useState([]);

  const createList = (dataEmployee) => {
    setLoading(true);
    setShowList(true);
    if (showList) {
      setFilterInfoEmployee(dataEmployee);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  // ! - usar para formatar dois nomes Ex. (henrique silva)
  const parseName = (oneName, secondName) => {
    const firstName = oneName || "";
    const lastName = secondName || "";
    var fullName = "";
    if (lastName.length > 0) {
      fullName = firstName.concat(" ", lastName);
    } else {
      fullName = firstName;
    }
    const formatName = fullName?.split(" ");
    for (var i = 0; i < formatName?.length; i++) {
      formatName[i] =
        formatName[i].charAt(0).toUpperCase() + formatName[i].slice(1);
    }
    let result = formatName?.join(" ");

    return result;
  };

  const filterEmployee = () => {
    const filterList = employeesInfo
      .filter((employee) =>
        filterCPFEmployee.length > 0
          ? employee.docEmployee.startsWith(filterCPFEmployee)
          : employee
      )
      .filter((employee) =>
        filterNameEmployee.length > 0
          ? employee.employeeName.includes(
              filterNameEmployee.toLocaleLowerCase()
            )
          : employee
      );

    setFilterInfoEmployee(filterList);
  };

  useEffect(() => {
    createList(employeesInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeesInfo]);

  useEffect(() => {
    setLoading(true);
    if (loadingEmployees) {
      props.getListEmployees();
    }
    setTimeout(() => {
      setLoadingEmployees(false);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingEmployees]);

  return (
    <DivSearchEmployee>
      <DivSearch>
        <Title>Consulta Funcionario</Title>
        <DivBtnFilter show={props.disableFilter}>
          <DivOrgInput>
            <NameLabel>CPF</NameLabel>

            <FormatCPF
              value={filterCPFEmployee}
              format="###.###.###-##"
              allowEmptyFormatting
              mask="_"
              onValueChange={(values, sourceInfo) => {
                setFilterCPFEmployee(values.value);
              }}
            />
          </DivOrgInput>
          <DivOrgInput>
            <NameLabel>Nome</NameLabel>
            <NameInput
              value={filterNameEmployee}
              onChange={(e) => setFilterNameEmployee(e.target.value)}
            />
          </DivOrgInput>
          <DivBtnSearch>
            <BtnSearch type="button" onClick={filterEmployee}>
              <Search />
            </BtnSearch>
            <BtnCancel
              type="button"
              onClick={() => {
                setFilterCPFEmployee("");
                setFilterNameEmployee("");
                setFilterInfoEmployee(employeesInfo);
              }}
            >
              <Close />
            </BtnCancel>
          </DivBtnSearch>
        </DivBtnFilter>
      </DivSearch>
      <DivTableSearch>
        {loading ? (
          <DivOrgLoading>
            <ClipLoader speedMultiplier={3} color={"#FFF"} />
          </DivOrgLoading>
        ) : (
          filterInfoEmployee.map((infoEmployee, index) => {
            return (
              <DivEmployee key={infoEmployee.idEmployee}>
                <DivCardEmployee>
                  <DivInfo>
                    <DivIdEmployee>
                      <IdInfo>{index + 1}</IdInfo>
                    </DivIdEmployee>
                    <DivEmployeeInfo>
                      <SpanName>
                        {parseName(
                          infoEmployee.firstName,
                          infoEmployee.lastName
                        )}
                      </SpanName>

                      <FormatCPFText
                        displayType="text"
                        value={infoEmployee.cpf || "00000000000"}
                        format="###.###.###-##"
                        allowEmptyFormatting
                        mask="_"
                      />
                    </DivEmployeeInfo>
                  </DivInfo>
                  <DivBtnEdit>
                    <BtnEdit
                      onClick={() => {
                        setEmployeePopUp(!employeePopUp);
                        setDataEmployeeUpdate(infoEmployee);
                      }}
                    >
                      <Edit />
                    </BtnEdit>
                    {employeePopUp &&
                      infoEmployee.idEmployee ===
                        dataEmployeeUpdate.idEmployee && (
                        <UpdateEmployee
                          setLoadingEmployees={setLoadingEmployees}
                          dataEmployeeUpdate={dataEmployeeUpdate}
                          employeePopUp={employeePopUp}
                          setEmployeePopUp={setEmployeePopUp}
                        />
                      )}
                    {/* Cria um use state e passa o id para o state E ele passa para a função de delete */}
                    <BtnView
                      onClick={() => {
                        setEmployeeView(!employeeView);
                        setSelectedEmployeeView(infoEmployee);
                        setDelEmployeeOption(false);
                      }}
                    >
                      <Visibility />
                    </BtnView>
                    <BtnRemove
                      // type="button"
                      onClick={() => {
                        setDelEmployeeOption(!delEmployeeOption);
                        setSelectedEmployee(infoEmployee);
                        setEmployeeView(false);
                      }}
                    >
                      <PersonRemove />
                    </BtnRemove>
                  </DivBtnEdit>
                </DivCardEmployee>
                {employeeView &&
                  infoEmployee.idEmployee ===
                    selectedEmployeeView.idEmployee && (
                    <InfoEmployee
                      selectedEmployeeView={selectedEmployeeView}
                      employeeView={employeeView}
                      setEmployeeView={setEmployeeView}
                    />
                  )}
                {delEmployeeOption &&
                  infoEmployee.idEmployee === selectedEmployee.idEmployee && (
                    <DeleteEmployee
                      setLoadingEmployees={setLoadingEmployees}
                      selectedEmployee={selectedEmployee}
                      delEmployeeOption={delEmployeeOption}
                      setDelEmployeeOption={setDelEmployeeOption}
                    />
                  )}
              </DivEmployee>
            );
          })
        )}
      </DivTableSearch>
    </DivSearchEmployee>
  );
}
