import React from "react";
import {
  DivOrgResumeClientPayment,
  DivInfoResumeClientPayment,
  InfoResumeClientPaymentResult,
  DivOrgInfo,
  DivBtnClose,
  BtnClose,
  DivOrgPrices,
  DivOrgTitle,
  TitleInfoOrder,
} from "./InfoResumeClientPaymentStyle";
import { Close } from "@styled-icons/material";
import { NumericFormat } from "react-number-format";

export default function InfoResumeClientPayment(props) {
  const infoPayment =
    props.selectPayView.infoPayment[0] || props.selectPayView.infoPayment;

  return (
    <DivOrgResumeClientPayment show={props.productView}>
      <DivBtnClose>
        <BtnClose onClick={() => props.setPaymentView(false)}>
          <Close height={30} />
        </BtnClose>
      </DivBtnClose>
      <DivOrgTitle>
        <TitleInfoOrder>Informações de Pagamento</TitleInfoOrder>
      </DivOrgTitle>
      <DivInfoResumeClientPayment>
        <DivOrgPrices>
          <DivOrgInfo>
            <InfoResumeClientPaymentResult>
              Forma de pagamento: {infoPayment?.formPayment}
            </InfoResumeClientPaymentResult>
          </DivOrgInfo>
          <DivOrgInfo>
            <InfoResumeClientPaymentResult>
              Valor que o cliente Pagou: {""}
              <NumericFormat
                // customInput={InfoResumeClientPaymentResult}
                displayType="text"
                value={infoPayment?.valueClientPayed}
                decimalSeparator=","
                thousandSeparator="."
                fixedDecimalScale
                decimalScale={2}
                prefix={"R$ "}
              />
            </InfoResumeClientPaymentResult>
          </DivOrgInfo>
          <DivOrgInfo>
            <InfoResumeClientPaymentResult>
              Troco: {""}
              <NumericFormat
                // customInput={InfoResumeClientPaymentResult}
                displayType="text"
                value={infoPayment?.valueChange}
                decimalSeparator=","
                thousandSeparator="."
                fixedDecimalScale
                decimalScale={2}
                prefix={"R$ "}
              />
            </InfoResumeClientPaymentResult>
          </DivOrgInfo>
          <DivOrgInfo>
            <InfoResumeClientPaymentResult>
              Valor da entrega: {""}
              <NumericFormat
                displayType="text"
                value={infoPayment?.valueDelivery}
                decimalSeparator=","
                thousandSeparator="."
                fixedDecimalScale
                decimalScale={2}
              />
            </InfoResumeClientPaymentResult>
          </DivOrgInfo>
        </DivOrgPrices>
        {infoPayment?.discountOption === "sim" && (
          <DivOrgPrices>
            <DivOrgInfo>
              <InfoResumeClientPaymentResult>
                Valor do desconto do Pedido: {""}
                <NumericFormat
                  displayType="text"
                  value={infoPayment?.valueDiscount}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale
                  decimalScale={2}
                  prefix={"R$ "}
                />
              </InfoResumeClientPaymentResult>
            </DivOrgInfo>
            {/* <DivOrgInfo>
              <InfoResumeClientPaymentResult>
                Porcentagem de Desconto: {""}
                <NumericFormat
                  displayType="text"
                  value={infoPayment.percentDiscount}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale
                  decimalScale={2}
                  suffix="%"
                />
              </InfoResumeClientPaymentResult>
            </DivOrgInfo> */}
          </DivOrgPrices>
        )}
      </DivInfoResumeClientPayment>
    </DivOrgResumeClientPayment>
  );
}
