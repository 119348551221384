export const yearsInfo = [
  { value: 2023 },
  { value: 2024 },
  { value: 2025 },
  { value: 2026 },
  { value: 2027 },
];

export const monthsInfo = [
  { value: "01", nameMonth: "Janeiro" },
  { value: "02", nameMonth: "Fevereiro" },
  { value: "03", nameMonth: "Março" },
  { value: "04", nameMonth: "Abril" },
  { value: "05", nameMonth: "Maio" },
  { value: "06", nameMonth: "Junho" },
  { value: "07", nameMonth: "Julho" },
  { value: "08", nameMonth: "Agosto" },
  { value: "09", nameMonth: "Setembro" },
  { value: 10, nameMonth: "Outubro" },
  { value: 11, nameMonth: "Novembro" },
  { value: 12, nameMonth: "Dezembro" },
];


