import React, { useState } from "react";
import {
  DivNav,
  Nav,
  OptionMenu,
  Bars,
  Bar,
  NavMenu,
  DivDrop,
  DropMenuSpan,
  ImgLogo,
  LiDiv,
  DivOrgMenu,
  NavLinkImg,
} from "./NavbarStyle";
import Logo from "../../images/LogoInicio.png";
import UserConfig from "../Configs/UserConfig/UserConfig";

export default function NavBar() {
  const [open, setOpen] = useState(false);

  return (
    <DivNav>
      <Nav open={open}>
        <NavLinkImg to="/home">
          <ImgLogo src={Logo} />
        </NavLinkImg>
        <Bars
          onClick={() => {
            setOpen(!open);
          }}
        >
          <Bar />
        </Bars>
        <NavMenu open={open}>
          <DivOrgMenu>
            <LiDiv>
              <OptionMenu>Empresa</OptionMenu>
              <DivDrop>
                <DropMenuSpan to="/home">Resumo</DropMenuSpan>
                <DropMenuSpan to="/resume/profits">Resumo Lucro</DropMenuSpan>
                <DropMenuSpan to="/resume/invoice">
                  Resumo Faturamento
                </DropMenuSpan>
                <DropMenuSpan to="/resume/expense">
                  Resumo Despesas
                </DropMenuSpan>
                <DropMenuSpan to="/resume/stock">Resumo Estoque</DropMenuSpan>
              </DivDrop>
            </LiDiv>
            <LiDiv>
              <OptionMenu>Comercial</OptionMenu>
              <DivDrop>
                <DropMenuSpan to="/comercial">Venda Balcão</DropMenuSpan>
              </DivDrop>
            </LiDiv>
            <LiDiv>
              <OptionMenu>Financeiro</OptionMenu>
              <DivDrop>
                <DropMenuSpan to="/financial">Resumo de Vendas</DropMenuSpan>
                <DropMenuSpan to="/financial/financial-entry-order">
                  Compras com Fornecedores
                </DropMenuSpan>
                <DropMenuSpan to="/financial/comission">Comissões</DropMenuSpan>
                <DropMenuSpan to="/financial/cashier">Caixa</DropMenuSpan>
                <DropMenuSpan to="/financial/debits">Despesa</DropMenuSpan>
              </DivDrop>
            </LiDiv>

            <LiDiv>
              <OptionMenu>Estoque</OptionMenu>
              <DivDrop>
                <DropMenuSpan to="/stock">Estoque</DropMenuSpan>
                <DropMenuSpan to="/stock/stock-out">
                  Retirar Item do Estoque
                </DropMenuSpan>
                <DropMenuSpan to="/stock/stock-item">Entrada no Estoque</DropMenuSpan>
                <DropMenuSpan to="/stock/etiquetas">Etiquetas</DropMenuSpan>
                {/* <DropMenuSpan to="/stock/stock-devolution">Devolução ao Estoque</DropMenuSpan> */}
              </DivDrop>
            </LiDiv>
            <LiDiv>
              <OptionMenu>Cadastros</OptionMenu>
              <DivDrop>
                <DropMenuSpan to="/new-cli">Cadastrar de Cliente</DropMenuSpan>
                <DropMenuSpan to="/new-provider">
                  Cadastrar de Fornecedor
                </DropMenuSpan>
                <DropMenuSpan to="/new-product">Cadastrar Produto</DropMenuSpan>
                <DropMenuSpan to="/new-employee">Cadastrar Funcionario</DropMenuSpan>
                <DropMenuSpan to="/new-position">Cadastrar Profissão</DropMenuSpan>
                <DropMenuSpan to="/new-user">Cadastrar Usuario</DropMenuSpan>
                <DropMenuSpan to="/new-brand">Cadastrar Marca</DropMenuSpan>
                <DropMenuSpan to="/new-delivery">
                  Cadastrar Delivery
                </DropMenuSpan>
                <DropMenuSpan to="/new-category">
                  Cadastrar Categoria
                </DropMenuSpan>
              </DivDrop>
            </LiDiv>
          </DivOrgMenu>

          <UserConfig open={open} />
        </NavMenu>
      </Nav>
      {/* <NotificationModal /> */}
    </DivNav>
  );
}
