import React, { useEffect, useState } from "react";
import {
  DivNewPosition,
  FormPosition,
  DivOrgPosition,
  LabelPosition,
  InputPosition,
  SubmitPosition,
  TitleNewPosition,
  DivBtnPosition,
  BtnRemovePosition,
  DivOrgLoading,
  DivOrgResults,
  InfoResult,
} from "./NewWorkPositionStyle";
import { useForm } from "react-hook-form";
import {
  createPosition,
  getInfoPosition,
} from "../../../store/registers/workPosition/position.actions";
import { useDispatch } from "react-redux";

import { ClipLoader } from "react-spinners";
import { useDebounce } from "use-debounce";

export default function NewPosition(props) {
  const dispatch = useDispatch();
  const [categoryInfo, setPositionInfo] = useState([]);
  const [namePosition, setNamePosition] = useState("");
  const [categoryError, setPositionError] = useState([]);
  const [namePositionSearch] = useDebounce(namePosition, 500);
  const [disableBtn, setDisableBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingListPositions, setLoadingListPositions] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState,
    formState: { isSubmitSuccessful },
  } = useForm();

  // Cadastrar Position
  const newPosition = async (dataPosition) => {
    setLoading(true);
    const newPosition = dataPosition;
    const categoryCreate = await dispatch(createPosition(newPosition));
    setPositionInfo(categoryCreate.payload);
    setTimeout(setLoading(false), 2000);
    setTimeout(setLoadingListPositions(true), 1500);
  };

  const verifyPosition = async (namePosition) => {
    const categoryExists = await dispatch(getInfoPosition(namePosition));
    setPositionError(categoryExists.payload);
  };

  useEffect(() => {
    if (loadingListPositions) {
      props.getListPositions();
    }
    setTimeout(setLoadingListPositions(false), 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingListPositions]);

  useEffect(() => {
    if (namePositionSearch.length === 0) {
      setPositionError(namePositionSearch);
    }

    if (namePositionSearch) {
      verifyPosition(namePositionSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [namePositionSearch]);

  useEffect(() => {
    if (categoryError.codeStatus === 200) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [categoryError.codeStatus]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      setTimeout(() => {
        reset();
      }, 1000);
      setTimeout(() => {
        setPositionInfo([]);
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, reset]);

  return (
    <DivNewPosition>
      <TitleNewPosition>Nova Profissão</TitleNewPosition>
      <FormPosition id="form-delivery" onSubmit={handleSubmit(newPosition)}>
        <DivOrgPosition>
          <LabelPosition>Nome da Profissão</LabelPosition>
          <InputPosition
            type="text"
            maxLength={30}
            {...register("namePosition", {
              onChange: (e) => {
                setNamePosition(e.target.value);
              },
            })}
          />
        </DivOrgPosition>
        {categoryError.codeStatus === 200 && (
          <DivOrgResults>
            <InfoResult>Categoria já cadastrada</InfoResult>
          </DivOrgResults>
        )}
        <DivBtnPosition>
          <BtnRemovePosition type="reset">Cancelar</BtnRemovePosition>
          <SubmitPosition type="submit" disabled={disableBtn}>
            Cadastrar
          </SubmitPosition>
        </DivBtnPosition>
      </FormPosition>
      {loading ? (
        <DivOrgLoading>
          <ClipLoader speedMultiplier={3} />
        </DivOrgLoading>
      ) : (
        (categoryInfo.errorStatus && (
          <DivOrgResults>
            <InfoResult>{categoryInfo.message}</InfoResult>
          </DivOrgResults>
        )) ||
        (categoryInfo.successStatus && (
          <DivOrgResults>
            <InfoResult>{categoryInfo.message}</InfoResult>
          </DivOrgResults>
        ))
      )}
    </DivNewPosition>
  );
}
