import React, { useEffect, useState } from "react";
import {
  DivUpdatePosition,
  FormPosition,
  DivOrgPosition,
  LabelPosition,
  InputPosition,
  SubmitPosition,
  DivBtnPosition,
  BtnRemovePosition,
  DivBtnClose,
  BtnClose,
  DivOrgLoading,
  DivOrgResults,
  InfoResult,
} from "./UpdateWorkPositionStyle";

import { useForm } from "react-hook-form";
import { Close } from "@styled-icons/material";

import {
  getInfoPosition,
  updtPosition,
} from "../../../store/registers/workPosition/position.actions";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";
import { useDebounce } from "use-debounce";

export default function UpdatePosition(props) {
  const deliveryDetail = props.dataPositionUpdate;
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const [deliveryInfo, setPositionInfo] = useState([]);
  const [namePosition, setNamePosition] = useState("");
  const [positionError, setPositionError] = useState([]);
  const [namePositionSearch] = useDebounce(namePosition, 500);
  const [disableBtn, setDisableBtn] = useState(false);

  const [loadingUpdatePosition, setLoadingUpdatePosition] = useState();
  // const [valuePosition, setValuePosition] = useState();
  const [dataPosition, setDataPosition] = useState({
    idPosition: deliveryDetail.idPosition || "",
    namePosition: deliveryDetail.namePosition || "",
  });

  const positionUptd = async () => {
    setLoadingUpdatePosition(true);
    const upPosition = await dispatch(updtPosition(dataPosition));
    setPositionInfo(upPosition.payload);
    setTimeout(() => {
      setLoadingUpdatePosition(false);
    });
    if (upPosition.payload.successStatus === true) {
      setTimeout(() => {
        props.setLoadingPositions(true)
        props.setPositionPopUp(false);
      }, 3000);
    }
  };

  const verifyPosition = async (namePosition) => {
    const positionExists = await dispatch(
      getInfoPosition(namePosition.toLowerCase())
    );
    setPositionError(positionExists.payload);
  };

  useEffect(() => {
    if (namePositionSearch.length === 0) {
      setPositionError(namePositionSearch);
    }

    if (namePositionSearch) {
      verifyPosition(namePositionSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [namePositionSearch]);

  useEffect(() => {
    if (positionError.codeStatus === 200) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [positionError.codeStatus]);

  return (
    <DivUpdatePosition show={props.PositionPopUp}>
      <FormPosition type="button" onSubmit={handleSubmit(positionUptd)}>
      <DivBtnClose>
        <BtnClose
          onClick={() => {
            props.setPositionPopUp(false);
            props.setLoadingPositions(true);
          }}
        >
          <Close />
        </BtnClose>
      </DivBtnClose>
        <DivOrgPosition>
          <LabelPosition>Codigo Profissão</LabelPosition>
          <LabelPosition>{dataPosition.idPosition}</LabelPosition>
        </DivOrgPosition>
        <DivOrgPosition>
          <LabelPosition>Profissão</LabelPosition>
          <InputPosition
            value={dataPosition.namePosition}
            {...register("namePosition", {
              onChange: (e) => {
                setDataPosition({
                  ...dataPosition,
                  namePosition: e.target.value,
                });
                setNamePosition(e.target.value);
              },
            })}
          />
        </DivOrgPosition>
        {positionError.codeStatus === 200 && (
          <DivOrgResults>
            <InfoResult>Já existe uma categoria com esse nome</InfoResult>
          </DivOrgResults>
        )}
        <DivBtnPosition>
          <BtnRemovePosition
            type="cancel"
            onClick={() => {
              props.setPositionPopUp(false);
            }}
          >
            Cancelar
          </BtnRemovePosition>
          <SubmitPosition type="submit" disabled={disableBtn}>
            Salvar
          </SubmitPosition>
        </DivBtnPosition>
        {loadingUpdatePosition ? (
          <DivOrgLoading>
            <ClipLoader speedMultiplier={3} />
          </DivOrgLoading>
        ) : (
          (deliveryInfo.errorStatus && (
            <DivOrgResults>
              <InfoResult>{deliveryInfo.message}</InfoResult>
            </DivOrgResults>
          )) ||
          (deliveryInfo.successStatus && (
            <DivOrgResults>
              <InfoResult>{deliveryInfo.message}</InfoResult>
            </DivOrgResults>
          ))
        )}
      </FormPosition>
    </DivUpdatePosition>
  );
}
